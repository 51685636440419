import Swiper from 'swiper/bundle';

// トップ
const topSlider = new Swiper('.slide .swiper-container', {
  speed: 1000,
  effect: "fade",
  loop: true,
  autoplay: {
    delay: 2000,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

topSlider.autoplay.stop();

setTimeout(function() {
  topSlider.autoplay.start();
}, 2000);
