
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', function () {

  gsap.from(
    '[data-loaded-animation="fadeIn"]',
    {
      opacity: 0,
      duration: 1.2,
      delay: 0.5
    }
  );

  gsap.from(
    '[data-loaded-animation="fadeInUp"]',
    {
      y: 30,
      opacity: 0,
      duration: 0.5,
      delay: 1,
      ease: "Power1.easeIn",
    }
  );

  // slideInLeft 共通
  document.querySelectorAll("[data-scroll-animation-common='slideInLeft']").forEach((el) => {
    gsap.fromTo(el,
      {
        "clip-path": "inset(0% 100% 0% 0%)",
      },
      {
        "clip-path": "inset(0% 0% 0% 0%)",
        duration: 1,
        scrollTrigger: {
          trigger: el,
          start: "top 80%",
          ease: "expo"
        },
      }
    )
  });

  // fadeInUp 共通
  document.querySelectorAll('[data-scroll-animation-common="fadeInUp"]').forEach((el) => {
    gsap.from(
      el,
      {
        y: 50,
        opacity: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: el,
          start: "top 80%",
          // ease: "expo"
        },
      }
    );
  });

  // slideIfadeInUpnLeft 個別 js_news__item
  gsap.from(
    '.js_news__item[data-scroll-animation-custom="fadeInUp"]',
    {
      y: 30,
      opacity: 0,
      duration: 0.8,
      delay: 0.5,
      stagger: {
        from: "start",
        amount: 0.8
      },
      scrollTrigger: {
        trigger: '.js_news__item[data-scroll-animation-custom="fadeInUp"]',
        start: "top 80%",
        ease: "expo"
      },
    }
  );

})