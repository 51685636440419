// トップページ　メインビジュアルの高さ調整

// スマホでの100vh調整
// $(document).ready(function(){
//   var hSize = $(window).height();
//   // アドレスバーを除いたサイズを付与
//   $('.hero').height(hSize);
//   $('.slide').height(hSize);
// });

// PC
// $(window).resize(function(){
//   var hSize = $(window).height();
//   $('.hero').height(hSize);
//   $('.slide').height(hSize);
// });